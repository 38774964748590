/* HomePage.css */

/* Background gradient animation */
.gradient-background {
  background: linear-gradient(to bottom, white, #e5f0fb);
}


/* the component-margin class is used to add padding to components on the home page */
.component-margin {
  padding-left: 70px;
  padding-right: 70px;
  margin-bottom: 100px;
}

/* Specific sections */
.about-us-section,
.client-carousel-section,
.experience-action-section,
.testimonials-section {
  margin-bottom: 100px;
}

.experience-action-section {
  margin-top: 50px;
}

.media-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.media-item-30 {
  width: 30%;
  vertical-align: middle;
  padding-right: 10px;
}

.media-item-70 {
  width: 70%;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.content-wrapper {
  padding: 0 20px;
}

table {
  width: 100%;
}

td {
  vertical-align: middle;
  padding: 0px;
}

/* Media query for screens smaller than 900px */
@media (max-width: 900px) {
  .component-margin {
    padding-left: 20px;
    padding-right: 20px;
  }

  .media-section {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .media-item-30,
  .media-item-70 {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
  }

  .table-responsive td {
    width: 50%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .about-us-section,
  .client-carousel-section,
  .experience-action-section,
  .testimonials-section {
    margin-bottom: 50px;
  }
}

/* Media query for screens smaller than 600px */
@media (max-width: 600px) {
  .component-margin {
    padding-left: 10px;
    padding-right: 10px;
  }

  .about-us-text {
    font-size: 0.8rem;
  }

  .testimonials-container {
    padding: 0 10px;
  }

  .testimonial-box {
    width: 100%;
    height: auto;
  }

  .testimonial-text {
    font-size: 0.8rem;
    padding: 0 5px;
  }

  .testimonial-image {
    width: 100%;
    max-width: 250px;
  }

  .about-us-section,
  .client-carousel-section,
  .experience-action-section,
  .testimonials-section {
    margin-bottom: 50px;
  }
}

/* Additional styles for other components */

/* Responsive styles for FakeInstagramPost */
.fake-instagram-container {
  width: 100%;
  max-width: 400px;
  border: 0px solid #dbdbdb;
  border-radius: 4px;
  margin: 0 auto;
  background-color: #fff;
  height: auto;
}

/* Responsive styles for About Us text */
.about-us-text {
  font-size: 1.2rem;
}

@media (max-width: 900px) {
  .about-us-text {
    font-size: 1rem;
  }
}

/* Responsive styles for Testimonials */
.testimonials-container {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

.testimonial-box {
  width: 100%;
  height: auto;
  margin-top: 0px !important;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  padding: 0;
  position: relative;
  background-color: transparent;
  border: 0px solid #ccc;
  border-radius: 10px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.testimonial-text {
  text-align: center;
  padding: 0 20px;
  overflow: hidden;
}

.testimonial-image {
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 20px;
  object-fit: cover;
}

@media (max-width: 900px) {
  .testimonials-container {
    padding: 0 10px;
  }

  .testimonial-box {
    width: 100%;
    height: auto;
  }

  .testimonial-text {
    padding: 0 10px;
  }
}

@media (max-width: 600px) {
  .testimonial-box {
    width: 100%;
    height: auto;
  }

  .testimonial-text {
    font-size: 0.8rem;
    padding: 0 5px;
  }

  .testimonial-image {
    width: 100%;
    max-width: 250px;
  }
}

/* Responsive styles for YouTube Video */
.youtube-video {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.youtube-video-frame {
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  width: 100%;
  max-width: 1100px;
  border-radius: 15px;
}


.youtube-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-video-buttons {
  display: flex;
  gap: 10px;
  padding: 10px;
  margin-top: 10px;
  justify-content: flex-end;
  width: 100%;
  max-width: 1100px; /* Match the max width of the video frame */
}

.youtube-button {
  border-radius: 10px;
  background-color: white;
  color: black;
  padding: 5px 10px; /* Adjust padding as needed */
}