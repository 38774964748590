@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif; /* Updated font-family */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', monospace; /* Updated font-family for code as well */
}


/* Carousel for main page */
.about_homepage_carousel-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .about_homepage_carousel-item {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .about_homepage_carousel-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 2px;
  }
  
  .about_homepage_carousel-text-container {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px;
  }
  
  /* Media query for screens smaller than 900px */
  @media (max-width: 900px) {
    .about_homepage_carousel-container {
      flex-direction: column;
      align-items: center;
    }
  
    .about_homepage_carousel-item {
      width: 100%;
      padding: 10px 0;
    }
  
    .about_homepage_carousel-text-container {
      width: 100%;
      justify-content: center;
      text-align: center;
    }
  }

/* ClientCarousel specific styles */
.client_carousel-container {
    position: relative;
    padding: 30px 0;
  }
  
  .slick-slide-item {
    padding: 0 15px; /* 30px / 2 for left and right padding */
    box-sizing: border-box;
  }
  
  .client_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Ensuring full height for the card */
  }
  
  .client_card-media {
    width: 100%;
    height: 240px; /* Set a fixed height for the card media */
    object-fit: cover;
    border-radius: 2px;
  }
  
  .client_card-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ensure content starts from the top */
    flex-grow: 1; /* Ensure the content takes up available space */
  }
  
  .client_card-content .MuiTypography-h5 {
    margin-top: 10px; /* Ensure there's space at the top */
  }
  
  .fab-hidden {
    display: none;
  }
  
  /* Show arrows on hover */
  .client_carousel-container:hover .fab-hidden {
    display: flex;
  }
  
  @media (max-width: 600px) {
    .slick-slide-item {
      padding: 0 15px;
    }
  }
/* TeamCarousel specific styles */
.team_carousel-container {
    position: relative;
    padding: 30px 0;
  }
  
  .team-slick-slide-item {
    padding: 0 15px; /* 30px / 2 for left and right padding */
    box-sizing: border-box;
    flex: 0 0 300px; /* Set a min-width for each slide */
  }
  
  .team_card-wrapper {
    width: 100%;
    height: 100%; /* Ensuring full height for the wrapper */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .team_card-carousel {
    width: 100%;
    max-width: 300px; /* Ensuring a consistent width for the card in the carousel */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 20px; /* Ensure margin is consistent */
    box-shadow: 3; /* Ensure consistent box shadow */
    text-align: center;
  }
  
  .team_fab-hidden {
    display: none;
  }
  
  /* Show arrows on hover */
  .team_carousel-container:hover .team_fab-hidden {
    display: flex;
  }
  
  @media (max-width: 600px) {
    .team-slick-slide-item {
      padding: 0 15px;
    }
  }
/* HomePage.css */

/* Background gradient animation */
.gradient-background {
  background: linear-gradient(to bottom, white, #e5f0fb);
}


/* the component-margin class is used to add padding to components on the home page */
.component-margin {
  padding-left: 70px;
  padding-right: 70px;
  margin-bottom: 100px;
}

/* Specific sections */
.about-us-section,
.client-carousel-section,
.experience-action-section,
.testimonials-section {
  margin-bottom: 100px;
}

.experience-action-section {
  margin-top: 50px;
}

.media-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.media-item-30 {
  width: 30%;
  vertical-align: middle;
  padding-right: 10px;
}

.media-item-70 {
  width: 70%;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.content-wrapper {
  padding: 0 20px;
}

table {
  width: 100%;
}

td {
  vertical-align: middle;
  padding: 0px;
}

/* Media query for screens smaller than 900px */
@media (max-width: 900px) {
  .component-margin {
    padding-left: 20px;
    padding-right: 20px;
  }

  .media-section {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .media-item-30,
  .media-item-70 {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
  }

  .table-responsive td {
    width: 50%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .about-us-section,
  .client-carousel-section,
  .experience-action-section,
  .testimonials-section {
    margin-bottom: 50px;
  }
}

/* Media query for screens smaller than 600px */
@media (max-width: 600px) {
  .component-margin {
    padding-left: 10px;
    padding-right: 10px;
  }

  .about-us-text {
    font-size: 0.8rem;
  }

  .testimonials-container {
    padding: 0 10px;
  }

  .testimonial-box {
    width: 100%;
    height: auto;
  }

  .testimonial-text {
    font-size: 0.8rem;
    padding: 0 5px;
  }

  .testimonial-image {
    width: 100%;
    max-width: 250px;
  }

  .about-us-section,
  .client-carousel-section,
  .experience-action-section,
  .testimonials-section {
    margin-bottom: 50px;
  }
}

/* Additional styles for other components */

/* Responsive styles for FakeInstagramPost */
.fake-instagram-container {
  width: 100%;
  max-width: 400px;
  border: 0px solid #dbdbdb;
  border-radius: 4px;
  margin: 0 auto;
  background-color: #fff;
  height: auto;
}

/* Responsive styles for About Us text */
.about-us-text {
  font-size: 1.2rem;
}

@media (max-width: 900px) {
  .about-us-text {
    font-size: 1rem;
  }
}

/* Responsive styles for Testimonials */
.testimonials-container {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

.testimonial-box {
  width: 100%;
  height: auto;
  margin-top: 0px !important;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  padding: 0;
  position: relative;
  background-color: transparent;
  border: 0px solid #ccc;
  border-radius: 10px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.testimonial-text {
  text-align: center;
  padding: 0 20px;
  overflow: hidden;
}

.testimonial-image {
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 20px;
  object-fit: cover;
}

@media (max-width: 900px) {
  .testimonials-container {
    padding: 0 10px;
  }

  .testimonial-box {
    width: 100%;
    height: auto;
  }

  .testimonial-text {
    padding: 0 10px;
  }
}

@media (max-width: 600px) {
  .testimonial-box {
    width: 100%;
    height: auto;
  }

  .testimonial-text {
    font-size: 0.8rem;
    padding: 0 5px;
  }

  .testimonial-image {
    width: 100%;
    max-width: 250px;
  }
}

/* Responsive styles for YouTube Video */
.youtube-video {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.youtube-video-frame {
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  width: 100%;
  max-width: 1100px;
  border-radius: 15px;
}


.youtube-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-video-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  margin-top: 10px;
  justify-content: flex-end;
  width: 100%;
  max-width: 1100px; /* Match the max width of the video frame */
}

.youtube-button {
  border-radius: 10px;
  background-color: white;
  color: black;
  padding: 5px 10px; /* Adjust padding as needed */
}
/* Import fonts from Google Fonts */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: #eef2ec !important;
  font-family: 'Montserrat', sans-serif; /* Apply Montserrat font */
}

#root {
  font-family: 'Montserrat', sans-serif; /* Updated to Montserrat */
}

.App {
  font-family: 'Montserrat', sans-serif; /* Updated to Montserrat */
}

.App-logo {
  font-family: 'Montserrat', sans-serif; /* Ensure Montserrat font is applied */
}

.App-header {
  font-family: 'Montserrat', sans-serif; /* Updated to Montserrat */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    font-family: 'Montserrat', sans-serif; /* Updated to Montserrat */
  }
}

#root, .App {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-x: hidden !important; /* Ensure no horizontal overflow */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700; /* Ensure the bold weight is used */
  width: 100%; /* Ensure the header takes full width */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor {
  display: inline-block;
  width: 10px;
  background-color: black;
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    background-color: transparent;
  }
}

