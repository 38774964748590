
/* Carousel for main page */
.about_homepage_carousel-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .about_homepage_carousel-item {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .about_homepage_carousel-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 2px;
  }
  
  .about_homepage_carousel-text-container {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px;
  }
  
  /* Media query for screens smaller than 900px */
  @media (max-width: 900px) {
    .about_homepage_carousel-container {
      flex-direction: column;
      align-items: center;
    }
  
    .about_homepage_carousel-item {
      width: 100%;
      padding: 10px 0;
    }
  
    .about_homepage_carousel-text-container {
      width: 100%;
      justify-content: center;
      text-align: center;
    }
  }

/* ClientCarousel specific styles */
.client_carousel-container {
    position: relative;
    padding: 30px 0;
  }
  
  .slick-slide-item {
    padding: 0 15px; /* 30px / 2 for left and right padding */
    box-sizing: border-box;
  }
  
  .client_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Ensuring full height for the card */
  }
  
  .client_card-media {
    width: 100%;
    height: 240px; /* Set a fixed height for the card media */
    object-fit: cover;
    border-radius: 2px;
  }
  
  .client_card-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ensure content starts from the top */
    flex-grow: 1; /* Ensure the content takes up available space */
  }
  
  .client_card-content .MuiTypography-h5 {
    margin-top: 10px; /* Ensure there's space at the top */
  }
  
  .fab-hidden {
    display: none;
  }
  
  /* Show arrows on hover */
  .client_carousel-container:hover .fab-hidden {
    display: flex;
  }
  
  @media (max-width: 600px) {
    .slick-slide-item {
      padding: 0 15px;
    }
  }
/* TeamCarousel specific styles */
.team_carousel-container {
    position: relative;
    padding: 30px 0;
  }
  
  .team-slick-slide-item {
    padding: 0 15px; /* 30px / 2 for left and right padding */
    box-sizing: border-box;
    flex: 0 0 300px; /* Set a min-width for each slide */
  }
  
  .team_card-wrapper {
    width: 100%;
    height: 100%; /* Ensuring full height for the wrapper */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .team_card-carousel {
    width: 100%;
    max-width: 300px; /* Ensuring a consistent width for the card in the carousel */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 20px; /* Ensure margin is consistent */
    box-shadow: 3; /* Ensure consistent box shadow */
    text-align: center;
  }
  
  .team_fab-hidden {
    display: none;
  }
  
  /* Show arrows on hover */
  .team_carousel-container:hover .team_fab-hidden {
    display: flex;
  }
  
  @media (max-width: 600px) {
    .team-slick-slide-item {
      padding: 0 15px;
    }
  }