body {
  margin: 0;
  font-family: 'Montserrat', sans-serif; /* Updated font-family */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', monospace; /* Updated font-family for code as well */
}
